import { FiXCircle } from 'react-icons/fi'

import Container from './Container'

type Props = {
  customMessage: string
  error?: string
}

const ErrorContainer = ({ customMessage, error }: Props): JSX.Element => {
  return (
    <Container className="pt-16 mx-auto " maxWidth={700}>
      <div className="flex flex-col items-center justify-center">
        <div className="rounded-full  bg-opacity-25">
          <FiXCircle className="text-red" size={100} />
        </div>
        <h1 className="text-xl text-gray mt-5">Algo no salió bien</h1>
        <p className="text-gray text-center">
          {customMessage ||
            `Ocurrió un problema de nuestro lado, pero no te preocupes. Nuestro equipo ya fue informado. Por favor, inténtalo en unos minutos más.`}
        </p>
        {error ? <p className="text-gray-300 mt-8 bg-gray !font-mono py-2 px-6 rounded-md">{error}</p> : null}
      </div>
    </Container>
  )
}

export default ErrorContainer
