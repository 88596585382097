import ErrorContainer from '@/components/base/ErrorContainer'
import Layout from '@/components/shared/Layout'
import { useAuthStore } from '@/dataStores/auth'

/**
 * Custom404 function.
 * @constructor
 */
export default function Custom404() {
  const [user] = useAuthStore((state) => [state.user])

  return (
    <>
      <Layout SEOProps={{ title: `Página no encontrada` }} showLeftBar={!!user} showRightBar={false}>
        <ErrorContainer
          customMessage={`La página que buscas no existe o está temporalmente no disponible. Te recomendamos revisar y verificar que la URL esté correcta o que lo intentes más tarde.`}
        />
      </Layout>
    </>
  )
}

Custom404.requireAuth = false
